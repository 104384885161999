import React from "react";
import tw from "tailwind.macro";

const styles = {
  wrap: tw`my-10`,
  text: tw`leading-normal text-center text-lg lg:text-xl mx-auto mb-4 max-w-lg`,
};

const SectionDesc = ({ description, className }) => (
  <section css={styles.wrap} className={className}>
    <p css={styles.text}>{description}</p>
  </section>
);

export default SectionDesc;
