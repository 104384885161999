import React from "react";

import tw from "tailwind.macro";

const styles = {
  wrap: tw`max-w-4xl mx-auto px-4`,
  text: tw`my-24 text-center text-xl py-8`,
  link: tw`text-watermelon`
};

const ContactBlock = () => (
  <div css={styles.wrap}>
    <div css={styles.text}>
      Have questions?{" "}
      <a
        href="https://masterclass-help.ted.com/hc/en-us"
        css={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        data-track-action="Check out FAQs"
      >
        Check out our FAQs
      </a>
      .
    </div>
  </div>
);

export default ContactBlock;
