import { graphql, useStaticQuery } from "gatsby";

// This was tested in chrome lighthouse audit comparing
// Gatsby Background Image vs a simple optimized jpg.
// The plain CSS background image performed better.
const useIndexPageData = () => {
  const {
    data: {
      frontmatter: { hero1, hero2, ...data },
    },
  } = useStaticQuery(
    graphql`
      query IndexPageData {
        data: mdx(frontmatter: { slug: { eq: "home" } }) {
          frontmatter {
            hero1 {
              checklist
              title
              img {
                publicURL
                # childImageSharp {
                #   fluid(quality: 80, maxWidth: 720) {
                #     ...GatsbyImageSharpFluid_withWebp
                #   }
                # }
              }
            }
            hero2 {
              checklist
              title
              img {
                publicURL
                # childImageSharp {
                #   fluid(quality: 80, maxWidth: 720) {
                #     ...GatsbyImageSharpFluid_withWebp
                #   }
                # }
              }
            }
            intro {
              description
              title
            }
            ctaBlock {
              title
              text
              cta {
                label
                href
              }
              cta2 {
                label
                href
              }
            }
          }
        }
      }
    `
  );

  return {
    ...data,
    hero1: { ...hero1, img: hero1.img.publicURL },
    hero2: { ...hero2, img: hero2.img.publicURL },
  };
};

export default useIndexPageData;
