import { css } from "@emotion/core";
import styled from "@emotion/styled";

export const AspectRatioBox = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  overflow: hidden;
  ${({ ratio }) => ratio === "16x9" && `padding-top: 56.25%;`}
  ${({ ratio }) => ratio === "iphone10" && `padding-top: 216.5%;`}
`;

export const aspectRatioInner = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
