import React from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import useIndexPageData from "../hooks/useIndexPageData";
import useUTMRedirect from "../hooks/useUTMRedirect";


import { blogMd, blogLg } from "../styles/breakpoints";

import Image from "../components/Image";
import Btn from "../components/Btn";
import ContactBlock from "../components/ContactBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/layout";
import SectionTitle from "../components/section_title";
import SectionDesc from "../components/section_desc";
import SplitBox from "../components/SplitBox";

import tw from "tailwind.macro";

export const imgQuery = graphql`
  query {
    imgSrc: file(relativePath: { eq: "MC_YT_Thumb1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const styles = {
  intro: tw`mt-12 xl:mt-24 mb-12 max-w-4xl mx-auto px-5`,
  pgBG: tw`bg-grey-lightest overflow-hidden`,
  imgWrap: tw`relative my-12`,
  img: css`
    width: calc(100% + 48px);
    max-width: none;
    margin: 60px -24px 0;

    @media (${blogMd}) {
      margin: 0 auto;
      width: 514px;
    }

    @media (${blogLg}) {
      width: 655px;
    }
  `,
};

const IndexPage = ({ intro, hero1, hero2, ctaBlock }) => {
  const { imgSrc } = useStaticQuery(imgQuery);

  return (
    <Layout>
      <Header />
      <div css={styles.pgBG}>
        <div css={styles.intro}>
          <SectionTitle large {...intro} />
        </div>

        <div css={styles.imgWrap}>
          <Image css={styles.img} {...imgSrc} alt="" />
        </div>

        <div css={styles.ctaWrap}>
          <Btn
            center
            href="https://www.youtube.com/playlist?list=PLJicmE8fK0EhSSdarWwp8GKSYUnPd1u8o"
            label="Get Started"
          />
        </div>
        <SectionDesc description="YouTube Courses are currently only available in the United States, but YouTube is working on expanding into other regions in 2023." />
        <SectionDesc description="The proceeds from this course support TED-Ed's nonprofit mission of creating and distributing free, high-quality animations." />
        <SplitBox reverse {...hero1} />
        <SplitBox {...hero2} />
      </div>
      <ContactBlock />
      <Footer />
    </Layout>
  );
};

const IndexPageWithData = (props) => (
  <IndexPage {...useIndexPageData()} {...props} />
);

const IndexPageWithRedirect = (props) => {
  useUTMRedirect();
  return <IndexPageWithData {...props} />;
};

export default IndexPageWithRedirect;
