import React from "react";

import { AspectRatioBox } from "../styles/aspect-ratio.styles.js";
import { OpenCircleCheck } from "./icons";

import tw from "tailwind.macro";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { md, lg, xxl } from "../styles/breakpoints";
import { Inter2r } from "../styles/blog";
import { rem } from "../styles/utilities.styles.js";

const styles = {
  wrap: tw`my-16 md:my-18 lg:my-24`,
  row: tw`flex flex-wrap`,
  colImg: tw`flex w-full lg:w-1/2`,
  checklistTall: css`
    @media (${md}) {
      max-width: ${rem(475)};
    }

    @media (${lg}) {
      padding: 0 ${rem(32)};
      margin: ${rem(16)} auto 0;
    }
  `,
  checklistItem: css`
    ${tw`flex items-start mb-5`};
    ${Inter2r};
    letter-spacing: -0.5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  checklistIcon: css`
    flex-shrink: 0;
    margin-right: ${rem(16)};
    margin-top: ${rem(5)};
  `,
  colContent: tw`flex w-full lg:w-1/2 items-center bg-black`,
  content: tw`w-4/5 lg:w-4/5 xl:w-2/3 mx-auto text-white text-center lg:text-left py-10`,
  title: tw`text-2xl lg:text-4xl mb-4`,
  text: tw`leading-normal text-base lg:text-xl`,
  img: tw`absolute pin-l pin-t w-full`,
};

const S = {
  Row: styled.div`
    ${styles.row};
    ${({ reverse }) => reverse && `flex-direction: row-reverse;`};
  `,
  CheckBoxRow: styled.div`
    ${tw`flex flex-col items-center max-w-lg mx-auto`};
    ${tw`lg:max-w-4xl lg:px-24`}
    ${({ reverse }) => (reverse ? tw`lg:flex-row-reverse` : tw`lg:flex-row`)};
  `,
  CheckBoxColImg: styled.div`
    ${tw`w-full bg-center bg-no-repeat bg-cover`};
    ${({ bg }) => `background-image:url(${bg});`};
    height: 350px;

    @media (${md}) {
      height: 350px;
      background-position: 20% 25%;
    }

    @media (${lg}) {
      width: 50vw;
      height: calc(0.75 * 50vw);
    }

    @media (${xxl}) {
      width: 720px;
      height: 540px;
    }
  `,
  CheckBoxCol: styled.div`
    ${tw`flex flex-col justify-center z-10`};

    @media (${lg}) {
      width: calc(100% - 50vw);
    }

    @media (${xxl}) {
      width: calc(100% - 720px);
    }
  `,
  CheckBoxContent: styled.div`
    ${tw`relative flex flex-col items-center justify-center z-10`};
    ${tw`lg:pt-24`}
    ${({ reverse }) => (reverse ? tw`lg:items-start` : tw`lg:items-end`)};
  `,
  CheckTitle: styled.h3`
    ${tw`block bg-black text-white`};
    ${tw`lg:absolute lg:pin-t lg:mt-0`};
    ${tw`xl:text-4xl`};
    height: ${rem(136)};
    padding: ${rem(28)} ${rem(25)};
    margin-top: ${rem(-44)};
    font-size: ${rem(30)};
    line-height: ${40 / 30};
    text-align: center;
    letter-spacing: ${rem(-1.15)};

    @media (min-width: 600px) {
      margin-top: ${rem(96 / -2)};
      height: ${rem(96)};
      white-space: nowrap;
      text-align: left;
    }

    @media (min-width: 1200px) {
      line-height: 1.333;
    }

    @media (${lg}) {
      margin-top: 0;
      ${({ reverse }) => (reverse ? `left: 0;` : `right: 0;`)}
    }
  `,
  Checklist: styled.ul`
    ${tw`list-reset pl-8 pr-8 pt-4`};
    ${tw`lg:pt-8`};
    ${tw`xl:pr-0`};

    ${({ tall }) => tall && styles.checklistTall};
  `,
};

const ChecklistBox = ({ reverse, img, title, checklist, tall }) => (
  <S.CheckBoxRow reverse={reverse}>
    <S.CheckBoxColImg bg={img} />
    <S.CheckBoxCol reverse={reverse}>
      <S.CheckBoxContent reverse={reverse}>
        <S.CheckTitle reverse={reverse}>{title}</S.CheckTitle>
        <S.Checklist tall={tall}>
          {checklist.map((item, idx) => (
            <li css={styles.checklistItem} key={idx}>
              <OpenCircleCheck
                width={28}
                height={28}
                css={styles.checklistIcon}
              />
              {item}
            </li>
          ))}
        </S.Checklist>
      </S.CheckBoxContent>
    </S.CheckBoxCol>
  </S.CheckBoxRow>
);

const ContentBox = ({ img, reverse, text, title }) => (
  <S.Row reverse={reverse}>
    <div css={styles.colImg}>
      <AspectRatioBox ratio="16x9">
        <img css={styles.img} src={img} alt="" />
      </AspectRatioBox>
    </div>
    <div css={styles.colContent}>
      <div css={styles.content}>
        <h3 css={styles.title}>{title}</h3>
        <p css={styles.text}>{text}</p>
      </div>
    </div>
  </S.Row>
);

const SplitBox = ({ checklist, ...props }) => (
  <div css={styles.wrap}>
    {checklist && <ChecklistBox checklist={checklist} {...props} />}
    {!checklist && <ContentBox {...props} />}
  </div>
);

export default SplitBox;
