import { useLocation } from "@reach/router";
import { useEffectOnce } from "react-use";

import _get from "lodash.get";

const redirects = {
  production: "https://masterclass.ted.com/web#/join-ted-masterclass",
  staging: "https://staging.masterclass.ted.com/web#/join-ted-masterclass",
  qa: "https://qa.masterclass.ted.com/web#/join-ted-masterclass",
};

const useUTMRedirect = () => {
  const { search } = useLocation();

  useEffectOnce(() => {
    if (search.includes("utm_medium=paidsocial")) {
      const url = _get(redirects, process.env.BRANCH, redirects["production"]);
      window.location.href = url;
    }
  });
};

export default useUTMRedirect;
